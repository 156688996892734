@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

.title {
    font-size: 50px;
    color: white;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    text-shadow: 5px 5px 12px rgba(0,0,0,0.5);
    padding-bottom: 30px;
}

.home-subtitle { 
    padding-top: 30px;
    font-size: 20px;
    font-weight: 400;
    font-family: 'Lato', sans-serif;
    color: #ffffff;
    align-items:start;
}

.brief {
    padding-top: 8px;
    color: white;
    font-weight: 300;
    font-size: 16px;
    font-family: 'Lato', sans-serif;
}

@media screen and (max-width: 992px) {
    .brief {
        padding-top: 12px;
        color: white;
        font-weight: 300;
        font-size: 20px;
        font-family: 'Lato', sans-serif;
    }
    .homeBody {
        padding-bottom: 10%;
    }
    .title {
        padding-top: 20px;
    }
}

@media screen and (max-width: 835px) {
    .brief {
        padding-top: 12px;
        color: white;
        font-weight: 300;
        font-size: 20px;
        font-family: 'Lato', sans-serif;
    }
    .homeBody {
        padding-bottom: 10%;
    }
    .title {
        padding-top: 20px;
    }
}

@media screen and (max-width: 770px) {
    .brief {
        padding-top: 12px;
        color: white;
        font-weight: 300;
        font-size: 20px;
        font-family: 'Lato', sans-serif;
    }
    .homeBody {
        padding-bottom: 10%;
    }
    .title {
        padding-top: 20px;
    }
}

@media screen and (max-width: 767px) {
    .brief {
        padding-top: 12px;
        color: white;
        font-weight: 300;
        font-size: 20px;
        font-family: 'Lato', sans-serif;
    }
    .homeBody {
        padding-bottom: 10%;
    }
    .title {
        padding-top: 20px;
    }
}

@media screen and (max-width: 585px) {
    .brief {
        padding-top: 12px;
        color: white;
        font-weight: 300;
        font-size: 20px;
        font-family: 'Lato', sans-serif;
    }
    .homeBody {
        padding-bottom: 10%;
    }
    .title {
        padding-top: 20px;
    }
}

@media screen and (max-width: 485px) {
    .brief {
        padding-top: 12px;
        color: white;
        font-weight: 300;
        font-size: 20px;
        font-family: 'Lato', sans-serif;
    }
    .homeBody {
        padding-bottom: 10%;
    }
    .title {
        padding-top: 20px;
    }
}

@media screen and (max-width: 395px) {
    .brief {
        padding-top: 12px;
        color: white;
        font-weight: 300;
        font-size: 20px;
        font-family: 'Lato', sans-serif;
    }
    .homeBody {
        padding-bottom: 10%;
    }
    .title {
        padding-top: 20px;
    }
}

@media screen and (max-width: 321px) {
    .brief {
        padding-top: 12px;
        color: white;
        font-weight: 300;
        font-size: 20px;
        font-family: 'Lato', sans-serif;
    }
    .homeBody {
        padding-bottom: 10%;
    }
    .title {
        padding-top: 20px;
    }
}