@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

.faqMaintitle {
    font-size: 50px;
    color: white;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    text-shadow: 5px 5px 12px rgba(0,0,0,0.5);
}

.faq-body {
    height: 100%;
    padding-top: 0px;
	padding-bottom: 80px;
}

.faqs {
    width: 100%;
    /* max-width: 768px; */
    margin: 0 auto;
    /* padding: 15px; */
}

.faqs .faq {
    /* margin-top: 15px; */
	margin: 15px;
	padding: 15px;
	/* background-color: #FFF; */
	/* border-radius: 8px; */
	/* box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2); */
    border-top: 0.1px solid #BFFFFF;
}

.faqs .faq .faq-title {
    color: white;
	position: relative;
	font-family: 'Lato', sans-serif;
	font-size: 22px;
	/* padding-right: 800px; */
    padding-top: 20px;
	text-align: start;
	transition: all 0.4s ease;
}

.faqs .faq .faq-title::after {
	content: '';
	position: absolute;
	top: 65%;
	right: 0px;
	transform: translateY(-50%);
	width: 30px;
	height: 30px;
	background-image: url('../download.png');
    color: black;
	background-position: center;
	background-size: contain;
	background-repeat: no-repeat;
	text-align: start;

	transition: all 00s ease-in;
}

.faqs .faq .faq-content {
    color: white;
	opacity: 0;
	max-height: 0;
    max-width: 1100px;
	overflow-y: hidden;
	font-family: 'Lato', sans-serif;
	font-size: 16px;
	text-align: start;
	transition: all 0s ease-out;
    font-weight: 300;
    /* font-size: 16px; */
}

.faqs .faq.open .faq-title {
	margin-bottom: 15px;
}

.faqs .faq.open .faq-title::after {
	transform: translateY(-10%) rotate(180deg);
}

.faqs .faq.open .faq-content {
	max-height: 1000px;
	opacity: 1;
}

@media screen and (max-width: 992px) {

}

@media screen and (max-width: 835px) {
    
}

@media screen and (max-width: 770px) {

}

@media screen and (max-width: 767px) {

}

@media screen and (max-width: 585px) {

}

@media screen and (max-width: 485px) {
	.faqMaintitle {
		padding-top: 15px;
		font-size: 50px;
		color: white;
		font-family: 'Poppins', sans-serif;
		font-weight: 400;
		text-shadow: 5px 5px 12px rgba(0,0,0,0.5);
	}
	.faq-body {
		padding-left: 8px;
		padding-right: 8px;
		height: 100%;
		padding-top: 0px;
		padding-bottom: 60px;
	}
	.faqs .faq {
		/* margin-top: 15px; */
		margin: 5px;
		padding: 5px;
		/* background-color: #FFF; */
		/* border-radius: 8px; */
		/* box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2); */
		border-top: 0.1px solid #BFFFFF;
	}
	.faqs .faq .faq-title {
		color: white;
		position: relative;
		font-family: 'Lato', sans-serif;
		font-size: 22px;
		/* padding-right: 800px; */
		padding-top: 20px;
		text-align: start;
		transition: all 0.4s ease;
		max-width: 300px;
	}	
	/* .faq-title {
		
	} */
	.faqs .faq .faq-title::after {
		content: '';
		position: absolute;
		top: 65%;
		left: 320px;
		text-align: end;
		transform: translateY(-50%);
		/* rigt: 50px; */
		width: 30px;
		height: 30px;
		background-image: url('../download.png');
		color: black;
		background-position: center;
		background-size: contain;
		background-repeat: no-repeat;
		text-align: start;
		transition: all 00s ease-in;
	}

	.faqs .faq .faq-content {
		color: white;
		opacity: 0;
		max-height: 0;
		max-width: 300px;
		overflow-y: hidden;
		font-family: 'Lato', sans-serif;
		padding-bottom: 30px;
		font-size: 18px;
		text-align: start;
		transition: all 0s ease-out;
		font-weight: 300;
		/* font-size: 16px; */
	}
	
}

@media screen and (max-width: 395px) {

}

@media screen and (max-width: 321px) {

}