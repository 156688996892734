* {
  box-sizing: border-box;
}

html {
  height: 100%;
}

body {
  margin: 0;
  background: -webkit-linear-gradient(210deg, #EC008C,#41004A);  
  background-repeat: no-repeat;
  background-attachment: fixed;
  /* background-image: url('background.png'); */
}

/* .container { */
  /* margin: 1rem; */
  /* text-align: center; */
/* } */

.background-circle {
  position: absolute;
  right: 0%;
  height: 100%;
  /* width: 120%; */
  /* top: 2%; */
}

.background-circle img {
  width: 100%;
  margin: 0px auto;
}

.background-circle-img {
  position: absolute;
}

@media screen and (max-width: 485px) {
  
  body {
    margin: auto;
    background: -webkit-linear-gradient(210deg, #EC008C,#41004A);  
    background-repeat: no-repeat;
    background-attachment: fixed;
  }
}