@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

.journeytitle {
    font-size: 50px;
    color: white;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    text-shadow: 5px 5px 12px rgba(0,0,0,0.5);
}

.subtitle {
    padding-top: 15px;
    font-size: 30px;
    font-weight: 400;
    font-style: italic;
    font-family: 'Lato', sans-serif;
    color: white;
}

.firstImageContent {
    padding-top: 60px;
    align-items: center;
    justify-content: center;
}

.firstImageContent img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    border-radius: 10px;
    /* height: 18rem; */
    /* width: 11rem; */
}

.secondImageContent {
    padding-top: 60px;
    align-items: center;
    justify-content: center;
}

.secondImageContent img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    border-radius: 10px;
}

.thirdImageContent {
    padding-top: 60px;
    align-items: center;
    justify-content: center;
}

.thirdImageContent img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    border-radius: 10px;
}

.fourthImageContent {
    padding-top: 60px;
    align-items: center;
    justify-content: center;
}

.fourthImageContent img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    border-radius: 10px;
}

.finalDot {
    width: 10px;
    align-items: center;
    margin: auto;
}

.firstverticalContent {
    display: block;
    margin-left: auto;
    margin-right: auto;
    font-family: 'Lato', sans-serif;
    color: white;
    /* width: 20%; */
    /* align-content: center; */
    font-size: 16px;
    text-align: justify;
    padding-top: 50px;
    font-weight: 300;
    /* border-right: 3px solid rgb(242, 233, 233); */
    /* height: 800px; */
    text-align: left;
    position: relative;
    word-spacing: 5px;
}

.firstPaneContent {
    font-family: 'Lato', sans-serif;
    color: white;
    align-content: center;
    font-weight: 200;
    font-size: 16px;
    
    padding: 30px;
    /* padding-right: 40px; */
    padding-top: 100px;

    display: block;
    margin-left: auto;
    margin-right: auto;

    font-weight: 300;
    text-align: center;
    word-spacing: 5px;
    /* max-width: 420px; */
}

.secondPaneContent {
    display: block;
    margin-left: auto;
    margin-right: auto;

    font-family: 'Lato', sans-serif;
    color: white;
    font-weight: 200;
    font-size: 16px;
    /* padding-left: 200px; */
    /* text-align: justify; */
    
    padding: 30px;
    padding-top: 100px;
    font-weight: 300;
    text-align: center;
    word-spacing: 5px;
    /* max-width: 420px; */
}

.thirdPaneContent {
    display: block;
    margin-left: auto;
    margin-right: auto;
    padding: 20px;
    
    font-family: 'Lato', sans-serif;
    color: white;
    font-weight: 200;
    font-size: 16px;
    /* padding-left: 200px; */
    /* text-align: justify; */
    
    padding: 30px;
    padding-top: 70px;
    font-weight: 300;
    text-align: left;
    word-spacing: 5px;    
    /* max-width: 420px; */
}

.fourthPaneContent {
    display: block;
    margin-left: auto;
    margin-right: auto;

    font-family: 'Lato', sans-serif;
    color: white;
    font-weight: 200;
    font-size: 16px;
    /* padding-left: 200px; */
    /* text-align: justify; */
    
    padding: 30px;
    padding-top: 100px;
    font-weight: 300;
    text-align: center;
    word-spacing: 5px;    
    /* max-width: 800px; */
}

.right-pane-1 {
    padding-top: 30px;
}

@media screen and (max-width: 992px) {
    .journeytitle {
        font-size: 50px;        
        padding-top: 20px;
    }
}

@media screen and (max-width: 835px) {
    .journeytitle {
        padding-top: 20px;
    }
}   

@media screen and (max-width: 770px) {
    .journeytitle {
        padding-top: 20px;
    }
}

@media screen and (max-width: 767px) {
    .journeytitle {
        padding-top: 20px;
    }
}

@media screen and (max-width: 585px) {
    .journeytitle {
        padding-top: 20px;
    }
}

@media screen and (max-width: 485px) {
    .journeytitle {
        padding-top: 20px;
    }

    .firstverticalContent {
        display: block;
        font-family: 'Lato', sans-serif;
        color: white;
        /* width: 20%; */
        align-content: center;
        font-weight: 300;
        font-size: 18px;
        text-align: left;
        padding-top: 20px;
        padding-left: 5px;
        padding-right: 5px;
        /* position: relative; */
        margin-left: auto;
        margin-right: auto;
        word-spacing: 5px;
    }
    
}

@media screen and (max-width: 395px) {
    .journeytitle {
        padding-top: 20px;
    }
}

@media screen and (max-width: 321px) {
    .journeytitle {
        padding-top: 20px;
    }
}