@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');
.desktopNabarWrapper .navbar-brand {
    margin-left: 0px;
}
 
a.navbarLink {
  font-family: 'Poppins', sans-serif !important;
  color: #fff !important;
  padding: 8px 18px !important;
  font-size: 14px !important;
  font-weight: 150 !important;
  line-height: 25px !important;
  letter-spacing: 0em;
  text-align: left;
}

a.navbarLink.active {
  background: rgba(255, 255, 255, 0.2);
  color: black;
  border-radius: 15px;
  /* height: 40px; */
  /* width: 12px; */
  /* -webkit-text-fill-color: transparent !important; */
  /* -webkit-background-clip: text; */
}
  
.navbar-brand {
  /* background-image: url('/images/logo-latest.png'); */
  max-width: 110px;
  max-height: 80px;
  width: auto;
  height: auto;
}

.navbarWrapper {
  padding: 10px 0px;
  position: relative;
  z-index: 12;
}

.navbarWrapper.desktopNabarWrapper {
  display: block;
}
.navbarWrapper.mobileNavbarWrapper {
  display: none;
}
  
.nav-link {
  color: #ffffff;
  font-size: 20px;
  text-decoration: none;
  padding: 10px 20px;
  margin: 0 5px;
  position: relative;
  border-radius: 15px;
  z-index: 1;
  overflow: hidden;
}

.nav-link::before,
.nav-link::after {
  content: "";
  position: absolute;
  background-color: rgba(255, 255, 255, 0.2);
  width: 0%;
  height: 100%;
  top: 0;
  /* transition: width 0.4s ease-in-out; */
  z-index: -1;
}

.nav-link::before {
  left: 50%;
  transform: translateX(-50%);
  border-radius: 15px;
}

.nav-link::after {
  right: 50%;
  transform: translateX(50%);
  border-radius: 5px;
}

.nav-link:hover::before,
.nav-link:hover::after {
  width: 100%;
}

@media screen and (max-width: 992px) {

}

@media screen and (max-width: 835px) {
    
}

@media screen and (max-width: 770px) {

}

@media screen and (max-width: 767px) {

}

@media screen and (max-width: 585px) {

}

@media screen and (max-width: 485px) {
  .navbar-brand {
    /* background-image: url('/images/logo-latest.png'); */
    max-width: 110px;
    max-height: 80px;
    padding-left: 130px;
    width: auto;
    height: auto;
  }
  
  a.navbarLink {
    font-family: 'Poppins', sans-serif !important;
    color: black !important;
    padding: 18px 18px !important;
    font-size: 18px !important;
    font-weight: 500 !important;
    line-height: 25px !important;
    letter-spacing: 0em;
    text-align: left;
  }
  a.navbarLink.active {
    background: pink;
    color: black;
    border-radius: 15px;
    /* height: 40px; */
    /* width: 12px; */
    /* -webkit-text-fill-color: transparent !important; */
    /* -webkit-background-clip: text; */
  }
  .nav-link {
    color: #ffffff;
    font-size: 20px;
    text-decoration: none;
    padding: 10px 20px;
    padding-top: 200px;
    margin: 0 5px;
    position: relative;
    border-radius: 15px;
    z-index: 1;
    overflow: hidden;
  }
  
  .nav-link::before,
  .nav-link::after {
    content: "";
    position: absolute;
    background-color: pink;
    width: 0%;
    height: 100%;
    top: 0;
    /* transition: width 0.4s ease-in-out; */
    z-index: -1;
  }

  .mobilemenuNavs {
    color: white;
    font-size: 18px;
    font-weight: 400;
  }
  
  .nav-link::before {
    left: 50%;
    transform: translateX(-50%);
    border-radius: 15px;
  }
  
  .nav-link::after {
    right: 50%;
    transform: translateX(50%);
    border-radius: 5px;
  }
  
  .nav-link:hover::before,
  .nav-link:hover::after {
    width: 100%;
  }
}

@media screen and (max-width: 395px) {
  .navbar-brand {
    /* background-image: url('/images/logo-latest.png'); */
    max-width: 110px;
    max-height: 80px;
    padding-left: 105px;
    width: auto;
    height: auto;
  }
  a.navbarLink {
    font-family: 'Poppins', sans-serif !important;
    color: black !important;
    padding: 18px 18px !important;
    font-size: 16px !important;
    font-weight: 400 !important;
    line-height: 25px !important;
    letter-spacing: 0em;
    text-align: left;
  }
  a.navbarLink.active {
    background: pink;
    color: black;
    border-radius: 15px;
    /* height: 40px; */
    /* width: 12px; */
    /* -webkit-text-fill-color: transparent !important; */
    /* -webkit-background-clip: text; */
  }
  .nav-link {
    color: #ffffff;
    font-size: 20px;
    text-decoration: none;
    padding: 10px 20px;
    padding-top: 200px;
    margin: 0 5px;
    position: relative;
    border-radius: 15px;
    z-index: 1;
    overflow: hidden;
  }
  
  .nav-link::before,
  .nav-link::after {
    content: "";
    position: absolute;
    background-color: pink;
    width: 0%;
    height: 100%;
    top: 0;
    /* transition: width 0.4s ease-in-out; */
    z-index: -1;
  }
  
  .nav-link::before {
    left: 50%;
    transform: translateX(-50%);
    border-radius: 15px;
  }
  
  .nav-link::after {
    right: 50%;
    transform: translateX(50%);
    border-radius: 5px;
  }
  
  .nav-link:hover::before,
  .nav-link:hover::after {
    width: 100%;
  }
}

@media screen and (max-width: 321px) {
  a.navbarLink {
    font-family: 'Poppins', sans-serif !important;
    color: black !important;
    padding: 18px 18px !important;
    font-size: 16px !important;
    font-weight: 400 !important;
    line-height: 25px !important;
    letter-spacing: 0em;
    text-align: left;
  }
  a.navbarLink.active {
    background: pink;
    color: black;
    border-radius: 15px;
    /* height: 40px; */
    /* width: 12px; */
    /* -webkit-text-fill-color: transparent !important; */
    /* -webkit-background-clip: text; */
  }
  .nav-link {
    color: #ffffff;
    font-size: 20px;
    text-decoration: none;
    padding: 10px 20px;
    padding-top: 200px;
    margin: 0 5px;
    position: relative;
    border-radius: 15px;
    z-index: 1;
    overflow: hidden;
  }
  
  .nav-link::before,
  .nav-link::after {
    content: "";
    position: absolute;
    background-color: pink;
    width: 0%;
    height: 100%;
    top: 0;
    /* transition: width 0.4s ease-in-out; */
    z-index: -1;
  }
  
  .nav-link::before {
    left: 50%;
    transform: translateX(-50%);
    border-radius: 15px;
  }
  
  .nav-link::after {
    right: 50%;
    transform: translateX(50%);
    border-radius: 5px;
  }
  
  .nav-link:hover::before,
  .nav-link:hover::after {
    width: 100%;
  }
}